.title {
    position: absolute;
    left: 15vw;
    top: 38vh;
}

.title svg {
    height: 50px;
    width: auto;
    animation: fill .5s ease forwards 1.4s;
    animation-delay: 3s;
    animation-iteration-count: 1;
}
    @keyframes fill {
        0% {
            fill: transparent;
        }
        
        100% {
            fill: #fff;
        }
    }

    svg path:nth-child(1) {
        stroke-dasharray: 800px;
        stroke-dashoffset: 800px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }
    svg path:nth-child(2) {
        stroke-dasharray: 500px;
        stroke-dashoffset: 500px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }
    svg path:nth-child(3) {
        stroke-dasharray: 1000px;
        stroke-dashoffset: 1000px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }
    svg path:nth-child(4) {
        stroke-dasharray: 500px;
        stroke-dashoffset: 500px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }
    svg path:nth-child(5) {
        stroke-dasharray: 800px;
        stroke-dashoffset: 800px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }

    svg path:nth-child(6) {
        stroke-dasharray: 600px;
        stroke-dashoffset: 600px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }

    svg path:nth-child(7) {
        stroke-dasharray: 600px;
        stroke-dashoffset: 600px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: line-anim 1.4s linear forwards;
        animation-delay: 1.5s;
    }

    @keyframes line-anim {
        100% {
            stroke-dashoffset: 0;
        }
    }

    .subTitle {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        animation: translateTitle 1s ease forwards 1.5s;
        animation-delay: 3s;
        animation-iteration-count: 1;
        transform: translateY(-25px);
        opacity: 0;
    }

    @keyframes translateTitle {
        0% {
            opacity: 0;
            transform: translateY(-25px);
        }
        50% {
            opacity: 0;
            transform: translateY(-12.5px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

.projectLink {
    font-family: integral;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    transform: translate(-50%,-50%);
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.projectLink::after {
    content: '';
    width: 0%;
    height: 4px;
    background-color: white;   
    margin: auto;
    display: block; 
    margin-top: 10px;
}

.projectLink:hover::after {
    width: 100%;
    transition: width 0.2s linear;
}

@media only screen and (max-width: 600px) {
    .title {
        left: 10vw;
    }

    .title svg {
        height: 38px;
    }
}