.project-content{
    position: absolute;
    background-color: #151515;
    margin: 0 200px;
}

.project-content *{
    color: rgba(255, 255, 255, 0.8) !important;
}

.project-about a{
    text-decoration: underline;
    margin: 5px 0;
    display: block;
}

.project-cover {
    width: 100%;
    height: 50vh;
    position: relative;
}

.gradient-cover {
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);    position: absolute;
    bottom: 0;
}

.backTo{
    display: inline-flex;
    margin-top: 40px;
}

.backTo img{
    align-content: center;
}

.backTo span{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-left: 20px;
}

.project-title {
    margin-top: 40px;
}

.project-content span{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.project-about {
    margin-top: 80px;
    display: flex;
    width: 100%;
}

.project-about-text {
    width: 74%;
    margin-right: 40px;
}

.project-content h3 {
    margin-bottom: 10px;
}

.project-about-links {
    margin-top: 30px;
}

.project-context{
    margin-top: 120px;
}

.project-context-text{
    display: flex;
    margin-top: 40px;
}

.project-context-text p{
    margin-right: 20px;
    display: block;
}

.project-context-text img{
    width: 422px;
    height: 360px;
    object-fit: cover;
}

.project-demarche {
    margin-top: 120px;
}

.project-demarche-blocks{
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 40px;
}

.about-skills-block span{
    font-weight: bold;
}

.project-demarche-blocks .about-skills-block p{
    margin-bottom: 10px;
}

.about-skills-block-display{
    display: none;
}

.project-files{
    margin-top: 40px;
}

.project-files-img {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
}

.project-files-img img{
    width: 100%;
}

.project-files img{
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.project-files-maquettes {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
}


.project-files-maquettes-kodo {
    grid-template-columns: 3fr 3fr 1fr 1fr;
}

.project-files-maquettes-topten {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.project-files-maquettes img{
    width: 100%;
}

.project-cover img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

@media only screen and (max-width: 765px) {
    .project-content{
        margin: 0 16px;
    }

    .project-about {
        display: block;
    }

    .project-context-text {
        display: block;
    }

    .project-demarche-blocks {
        grid-template-columns: 1fr;

    }

    .project-files-img {
        grid-template-columns: 1fr;
    }

    .project-files-maquettes {
        grid-template-columns: 1fr;
    }

    .project-context-text img {
        width: 100%;
    }

    .project-content span {
        font-size: 14px;
    }

    .project-about {
        margin-top: 40px;
    }

    .project-about-text {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .project-about-links {
        margin-top: 20px;
    }

    .project-context {
        margin-top: 80px;
    }

    .project-context-text {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
    }

    .project-context-text div {
        order: 2;
    }
    .project-context-text img {
        order: 1;
        margin-bottom: 20px;
    }

    .project-context-text p {
        margin-right: 0px;
    }

    .project-demarche {
        margin-top: 80px;
    }

    .project-detail-content{
        position: absolute;
    }    

}