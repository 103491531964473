.content {
    position: fixed;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-template-areas: 'main';
	width: 100%;
	margin: 0 auto;
    height: 100%;
}

.content__slide {
	grid-area: main;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-template-areas: 'inner';
	align-items: center;
	opacity: 0;
}

.content__slide--current {
    opacity: 1;
}

.content__img {
    width: 100vh;
    height: 55vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-25%);
    opacity: 0;
    animation: fadeupimg 2.5s ease-out forwards;
}

@keyframes fadeupimg {
    0% {
        opacity: 0;
        transform: translate(-50%,-25%);
    }
    75% {
        opacity: 0;
        transform: translate(-50%,-37.5%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%,-50%);
    }
}

.content__img-inner {
    height: 55vh;
    width: 100vh;
}

.content__text-inner {
    font-family: Integral;
    font-size: 100px;
    line-height: 0.8;
}

.content__text-inner--stroke {
    -webkit-text-stroke: 2px white;
	text-stroke: 2px white;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.content__text-inner--bottom {
	transform: translate3d(0,-50%,0);
    display: block;
}

.content__text-wrap {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    position: relative;
    animation: fadeup 3.5s ease-out forwards;
}

.content__text {
    overflow: hidden;
    flex: 1;
    opacity: 0;
    pointer-events: none;
}

.content__text:nth-child(6) {
	opacity: 1;
}

.content__text--full {
    flex: none;
}

.content__nav{
    position: absolute;
    top: 65%;
    left: 72%;
    transform: translate(45%,0%);
    animation: fadeup 3.5s ease forwards;
    z-index: 10;
}

.content__nav-button{
    font-family: Integral;
    border: none;
    background-color: transparent;
    font-size: 20px;
    display: block;
    margin: 10px 0;
    padding: 0 !important;
}

.content__nav-button:hover{
    background-color: transparent;
    color: white !important;
}

.next{
    margin-left: 30px;
}

.left{
    margin-right: 30px;
}

.overlay-img {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}

.pagination span{
    font-family: Integral !important;
    font-size: 16px;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%,0%);
}

.to-project{
    position: absolute;
    background-color: transparent;
    width: 100vh;
    height: 55vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
}


@media only screen and (max-width: 765px) {
    .content__img {
        width: 100vw;
    }

    .content__img-inner {
        width: 100vw;
    }

    .to-project {
        width: 100vw;
    }

    .content__text-inner {
        font-family: Integral;
        font-size: 50px;
        line-height: 0.8;
    }

    .content__nav{
       top: initial;
        bottom: 40px;
       left: 0;
       display: flex;
       transform: translate(0, 0);
       width: 100vw;
    }

    .content__nav-button{
        margin: 0 auto;
        width: initial;
     }

    .next{
        order: 2;
        margin-left: 20px;
        height: 48px;

    }

    .prev{
        order: 1;
        height: 48px;
        margin-right: 20px;
    }

    .content__text{
        display: none;
    }

    .content__text:nth-child(6) {
        display: block;
        text-align: center;
    }
}