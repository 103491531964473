.navigation ul li {
    display: inline-flex;
    margin: 0 20px;
}

.navigation ul li span {
    font-family: "Integral" !important;
    font-size: 20px;
    color: #ffffff;
}

.navigation{
    margin-right: 10px;
}

header {
    position: absolute;
    justify-content: space-between;
    width: 100%;
    display: inline-flex;
    margin: 20px 0;
    z-index: 1000;
}

.logo img {
    height: 54px;
    width: 54px;
    margin-left: 40px;
}

.navigation ul li span::after {
    content: '';
    width: 0;
    height: 5px;
    background-color: white;   
    margin: auto;
    display: block; 
    margin-top: 10px;
}

.navigation ul li span:hover::after {
    width: 100%;
    transition: width 0.2s linear;
}

.navigation .active li span::after {
    content: '';
    width: 100%;
    height: 5px;
    background-color: white;   
    margin: auto;
    display: block; 
    margin-top: 10px;
    
}

.m-header{
    display: none;
}

@media only screen and (max-width: 765px) {
    .navigation{
        display: none;
    }

    .logo img {
        height: 48px;
        width: 48px;
        margin-left: 20px;
    }

    .m-header{
        display: block;
        position: absolute;
        z-index: 10000;
    }

    .m-header button{
        display: block;
        position: fixed;
        bottom: 40px;
        right: 20px;
        border: none;
        margin: 0;
    }

    .m-nav .logo {
        padding-top: 20px;
    }

    .m-nav{
        background-color: rgba(0, 0, 0, 0.8);
        height: 100vh;
        width: 100vw;
        opacity: 0;
        visibility: hidden;
        position: fixed;
    }

    .show-nav {
        opacity: 1;
        visibility: visible;
    }

    .m-nav ul {
        position: fixed;
        right: 20px;
        bottom: 120px;
        
    }

    .m-nav ul li {
        margin: 20px 0px;
        text-align: right;
    }

    .m-nav ul li span {
        font-family: "Integral" !important;
        font-size: 18px;
        color: #ffffff;
        position: relative;
        display: inline-block;
    }

    .m-nav ul li span::after {
        content: '';
        width: 0;
        height: 5px;
        background-color: white;   
        margin: auto;
        display: block; 
        margin-top: 10px;
    }
    
    .m-nav ul li span:hover::after {
        width: 100%;
        transition: width 0.2s linear;
    }
    
    .m-nav .active li span::after {
        content: '';
        width: 100%;
        height: 5px;
        background-color: white;   
        margin: auto;
        display: block; 
        margin-top: 10px;
        
    }

    .burger-menu {
        height: 48px;
        width: 48px;
        border: 1px solid white !important;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0;
    }

    .burger-menu:hover{
        background-color: rgba(0, 0, 0, 0.5);
    }

    .burger-content {
        margin: auto;
    }

    .burger-bar {
        display: block;
        width: 22px;
        height: 2px;
        position: relative;
        background: #ffffff;
        margin: 3px auto;
        transition: all 0.3s ease-in-out;
    }

    .burger-close .burger-bar:first-of-type {   
        transform: translateY(5px) rotate(135deg);
    }
    .burger-close .burger-bar:nth-of-type(2n) {   
        width: 0;
    }
    .burger-close .burger-bar:last-of-type {
        transform: translateY(-5px) rotate(-135deg);
    }

    .burger-text p {     
        font-size: 8px;
    }

    .burger-text p:last-of-type {
        display: none;
    }

    .burger-close p:first-of-type {
       display: none;
    }

    .burger-close p:last-of-type {
        display: block;
    }
}