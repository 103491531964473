.bgp {
    position: fixed;
    width: 100vw;
    height: 100%;
    background: url(../images/project.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fadeup 1.5s ease-out forwards;
}

@keyframes fadeup {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}