.bga {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: url(../images/about.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fadeup 1.5s ease-out forwards;
}

.about-content{
    position: absolute;
    margin: 0 200px;
    animation: up 2.5s ease-out forwards;
}

@keyframes up {
    0% {
        opacity: 0;
        transform: translateY(5%);
    }
    75% {
        opacity: 0;
        transform: translateY(1%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.about-intro{
    position: relative;
    display: flex;
    height: 100vh;
}

.about-text, .about-photo{
    margin: auto 0;
    vertical-align: middle;
}

.about-text{
    margin-right: 25px;
}

#scrolldown{
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
}

.about-text button{
    padding: 14px 50px;
    text-align: center;
    margin: 20px 0;
}

.about-photo {
    margin-left: 25px;
}

.about-photo img{
    height: 500px;
    width: 480px;
    object-fit: contain;
}

.about-content span{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.about-text p{    
    margin-top: 20px;
}
.about-logo {
    margin: 20px -10px;
}

.about-logo img{
    height: 32px;
    width: 32px;
    margin: 10px;
}

.about-skills{
    padding-top: 150px;
}

.about-content *{
    color: rgba(255, 255, 255, 0.8) !important;
}

.about-skills-content{
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
}

.about-skills-block {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);;
}

.about-skills-block img{
    margin-bottom: 30px;
}
.about-skills-block h3{
    margin-bottom: 10px;
}

.project-demarche-img{
    margin-top: 80px;
}

@media only screen and (max-width: 765px) {
    .about-content{
        margin: 0 16px;
        position: absolute;
    }

    .about-intro{
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        height: auto;
    }

    .about-photo {
        margin-left: 0px;
        order: 1;
    }
    .about-text {
        order: 2;
        margin-top: 40px;
        margin-right: 0;
    }

    .about-photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .about-skills-content {
        grid-template-columns: 1fr;
    }

    #scrolldown {
        display: none;
    }

    .about-skills {
        padding-top: 80px;
    }

    .about-skills-content {
        margin-top: 40px;
    }
}