@font-face {
    font-family: "Integral";   
    src: local("AirbnbCerealLight"),
      url("../fonts/IntegralCF-DemiBold.otf") format("truetype");
  }

* {
    margin: 0;
    padding: 0;
    color: #ffffff;
    box-sizing: border-box;
    cursor: none;
}

h1, h2, h3, h4, .navigation {
    font-family: "Integral"; 
}

h1{
    font-size: 68px;
}

h2{
    font-size: 48px;
}

h3 {
    font-size: 16px;
}

span, p, a {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
}

p{
    line-height: 1.8;
}

ul li {
    list-style-type: none; 
}

a {
    text-decoration: none;
}

canvas {
    position: absolute;
    z-index: -100;
}

button {
    background-color: transparent;
    color: white;
    font-family: Integral;
    font-size: 14px;
    border: 1px solid white;
    padding: 14px 0;
    text-align: center;
}

button:hover {
    color: black !important;
    background-color: white;
}

body {
    background-color: #151515;
}

header {
    position: fixed;
}

.legal{
    margin-bottom: 20px;
    text-align: center;
}

.legal span{
    font-size: 12px;
}

.about-contact{
    margin-top: 150px;
}

.about-contact-link{
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
    margin-bottom: 120px;
}


.about-contact-link svg {
    vertical-align: middle;
    margin-right: 20px;
}

.about-contact-link a {
    border: 1px solid white;
    font-size: 16px;
    font-family: Integral;
    padding: 14px 0;
    text-align: center;
}

.stroke-hover{
    stroke: white !important;
}

.fill-hover{
    fill: white !important;
}

.about-contact-link a:nth-child(1):hover{
    background-color: #BB001B;
    color: #ffffff !important;
    border: 1px solid #BB001B;
}

.about-contact-link a:nth-child(2):hover{
    background-color: #0A66C2;
    color: #ffffff !important;
    border: 1px solid #0A66C2;
}

.about-contact-link a:nth-child(3):hover{
    background-color: #FF7900;
    color: #ffffff !important;
    border: 1px solid #FF7900;
}

.about-contact-link a:nth-child(4):hover{
    background-color: #B3BFFF;
    color: #ffffff !important;
    border: 1px solid #B3BFFF;
}

.showup, .showup-footer {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.6s ease-out,
    opacity 0.6s ease-out;
}

.showup-active {
    transform: translateY(0);
    opacity: 1;
}

.cursor {
    position: fixed;
    background: white;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    z-index: 10000;
    pointer-events: none;

}

.cursor-follower {
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    z-index: 1000;
    pointer-events: none;
    transition: width 0.5s, height 0.5s;
}

.follower-hover {
    width: 70px;
    height: 70px;
}

.follower-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    text-align: center;
    position: absolute;
    left: 50%;
    width: 200%;
    bottom: -25%;
    transform: translate(-50%,0%);
    opacity: 0;
    transition: opacity 0.5s;
}

.follower-text-hover {
    opacity: 1;
}

.scroll-top{
    position: fixed ;
    height: 48px;
    width: 48px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 40px;
    right: 40px;
    opacity: 0;
    transition: opacity 0.6s ease-out;
}

.showup-scroll {
    opacity: 1;
}

.loader{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #151515;
    z-index: 100000;
    transform: opacity 2s ease-out;
}

.loader span {
    font-family: Integral;
    font-size: 38px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: breathing 0.7s infinite alternate;
}

@keyframes breathing { 
    from { opacity: 0.2; } 
  }

@media only screen and (max-width: 765px) {
    .loader span {
        font-size: 28px;
    }

    .cursor {
        display: none;
    
    }
    
    .cursor-follower {
        display: none;
    }

    h1{
        font-size: 48px;
    }
    h2 {
        font-size: 38px;
    }

    button{
        width: 100%;
    }

    .about-contact{
        margin-top: 80px;
    }

    .about-contact-link {
        grid-template-columns: 1fr;
        margin-top: 40px;
        margin-bottom: 80px;
    }

    .scroll-top{
        bottom: 106px;
        right: 20px;
    }
}